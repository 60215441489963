<script lang="ts" setup>
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'

  const { toRoute } = useRouteRecord()
</script>

<template>
  <div class="c-navigation-content">
    <div class="c-navigation-content__aside"></div>
    <div class="c-navigation-content__center"></div>
    <div class="c-navigation-content__aside"></div>
  </div>
</template>

<style lang="scss" scoped src="./_NavigationContent.scss" />
